<!-- =========================================================================================
    File Name: Error404.vue
    Description: 404 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="page">
        <nav role="navigation">
            <div class="nav-background">
                <div class="img-background"></div>
            </div>
            <div class="nav-wrapper container">
                <a href="/" class="brand-logo">
                    <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                </a>
                <a href="#" data-target="dropdown-lang" class="dropdown-trigger btn transparent white-text z-depth-0">
                    <i18n />
                </a>
                <ul class="right hide-on-med-and-down">
                    <li><a class="white-text" @click="scrollMeTo('benefits')"><strong>{{$t('landing.benefits')}}</strong></a></li>
                    <li>
                        <a href="Fees.pdf" target="_blank"><strong>{{$t('landing.fees')}}</strong></a>
                    </li>
                    <li><a href="/faqs"><strong>FAQ</strong></a></li>
                    <li><a href="/contact"><strong>{{$t('landing.contact')}}</strong></a></li>
                </ul>
                <a href="#" data-target="nav-mobile" class="sidenav-trigger"><i class="material-icons">menu</i></a>
                <ul id="nav-mobile" class="sidenav">
                    <li><a @click="scrollMeTo('benefits')">{{$t('landing.benefits')}}</a></li>
                    <li><a href="Fees.pdf">{{$t('landing.fees')}}</a></li>
                    <li><a href="/faq">{{$t('landing.faq')}}</a></li>
                    <li><a href="/contact">{{$t('landing.contact')}}</a></li>
                </ul>
                <div class="nav-header nav-header-gateway center">
                    <h1>CactusCard</h1>
                    <h3 class="light flow-text cactuscard-subtitle" v-html="$t('landing.subtitle')"></h3>
                </div>
            </div>
        </nav>

        <div id="content">
            <div  class="section smilecard">
                <div class="container">
                    <div class="row row-flex">
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3">
                                    <i class="vs-icon material-icons icon-deal">card_travel</i>
                                </h2>
                                <h6 class="f-16 center deep-purple-text text-darken-3">{{$t('landing.buy')}}</h6>
                                <p class="f-13 light">{{$t('landing.buy_subtitle')}}</p>
                            </div>
                        </div>
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3">
                                    <i class="vs-icon material-icons icon-deal">local_atm</i>
                                </h2>
                                <h6 class="f-16 center deep-purple-text text-darken-3" v-html="$t('landing.withdraw')"></h6>
                                <p class="f-13 light">{{$t('landing.withdraw_subtitle')}}</p>
                            </div>
                        </div>
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3">
                                    <i class="vs-icon material-icons icon-deal">add_shopping_cart</i>
                                </h2>
                                <h6 class="f-16 center deep-purple-text text-darken-3" v-html="$t('landing.pay')"></h6>
                                <p class="f-13 light">{{$t('landing.pay_subtitle')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="apply-now center">
                        <a class="btn btn-large btn-rounded btn-flat waves-effect green accent-4" href="/request">{{$t('landing.start_today')}} </a>
                    </div>
                </div>
            </div>


            <div class="cardtype section">
                <div class="container">
                    <h3 class="light center deep-purple-text text-darken-3">{{$t('landing.plastic_or_virtual')}}</h3>
                    <div class="row row-flex">
                        <div class="col s12 m6">
                            <div class="card-panel white z-depth-3">
                                <h6 class="deep-purple-text text-darken-3">
                                    <i class="vs-icon material-icons icon-deal">credit_card</i>
                                    <span class="f-16">{{$t('landing.plastic')}}</span>
                                </h6>
                                <p class="f-13 light">{{$t('landing.plastic_subtitle1')}}</p>
                                <p class="f-13 light last" v-html="$t('landing.plastic_subtitle2')"></p>
                                <a class="btn btn-medium btn-rounded btn-flat waves-effect green accent-4" href="/request">{{$t('landing.plastic_want')}} </a>

                            </div>
                        </div>
                        <div class="col s12 m6">
                            <div class="card-panel white z-depth-3">
                                <h6 class="deep-purple-text text-darken-3">
                                    <i class="vs-icon material-icons icon-deal">card_membership</i>
                                    <span class="f-16">{{$t('landing.virtual')}}</span>
                                </h6>
                                <p class="f-13 light">{{$t('landing.virtual_subtitle1')}}</p>
                                <p class="f-13 light last">{{$t('landing.virtual_subtitle2')}}</p>

                                <a class="btn btn-medium btn-rounded btn-flat waves-effect green accent-4" href="/request">{{$t('landing.virtual_want')}}</a>


                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref="benefits" class="cardtype section">
                <div class="container">
                    <h3 class="light center deep-purple-text text-darken-3">{{$t('landing.benefits')}}</h3>
                    <div class="row row-flex">
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3"><i class="icon_user-plus"></i></h2>
                                <ul class="center f-13 purple-text text-darken-3 text-left" v-html="$t('landing.benefits1')">
                                </ul>
                            </div>
                        </div>
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3"><i class="icon_user-plus"></i></h2>
                                <ul class="center f-13 purple-text text-darken-3 text-left" v-html="$t('landing.benefits2')">
                                </ul>
                            </div>
                        </div>
                        <div class="col s12 m4">
                            <div class="card-panel z-depth-3">
                                <h2 class="center deep-purple-text text-darken-3"><i class="icon_user-plus"></i></h2>
                                <ul class="center f-13 purple-text text-darken-3 text-left" v-html="$t('landing.benefits3')">
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cardtype section">

            </div>

            <div class="ready card-panel card-line darken-3">
                <div class="container center">
                    <h4 class="light white-text">{{$t('landing.havent_card')}}</h4>
                    <a class="btn btn-large btn-rounded btn-flat waves-effect btn-start" href="/request">{{$t('landing.begin')}}</a>

                </div>
            </div>


            <section class="smilecard secciones">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xs-12 col-sm-5 imgcard">
                            <img class="img-responsive" src="@/assets/images/cactus/CactusCard1.png" >
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <footer class="page-footer text-center">
            <div class="container">
                <div class="row">
                    <div class="col l4 s12">
                        <a href="" class="brand-logo">
                            <img src="@/assets/images/logo/cactus-big-logo1_white.png" alt="Cactus Logo" />
                        </a>
                    </div>
                    <div class="col l8 s12">
                        <div class="row">
                            <div class="col s12 m6 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.company')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/terms">{{$t('landing.terms_label')}}</a></li>
                                    <li><a class="text-white" href="/privacy">{{$t('landing.privacy_label')}}</a></li>
                                    <li><a class="text-white" href="/mpolicy">{{$t('landing.policy_label')}}</a></li>
                                </ul>
                            </div>
                            <!--                            <div class="col s12 m3">-->
                            <!--                                <h6 class="heading f-16 purple-text text-darken-3">Products</h6>-->
                            <!--                                <ul>-->
                            <!--                                    <li><a href="<?php echo url_for('home/gateway') ?>">Gateway</a></li>-->
                            <!--                                </ul>-->
                            <!--                            </div>-->
                            <div class="col s12 m3 text-left">
                                <h6 class="heading f-16 purple-text text-darken-3">{{$t('landing.help')}}</h6>
                                <ul>
                                    <li><a class="text-white" href="/contact">{{$t('landing.contact')}}</a></li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="footer-copyright transparent">
                <div class="container text-darken-1">
                    Copyright © 2020 Cactus Prepaid. All rights reserved.
                    <div style="margin-top:0.1rem"></div>
                    <small>
                        Guareschi y Paz SA de CV
                        Meseta 10, Apart 2LT, Benito Juarez, CP 77505, Quintana Roo, Mexico

                    </small>
                </div>
            </div>
        </footer>

    </div>
</template>
<script>


    import I18n   from '../../layouts/components/navbar/I18n.vue'
    export default {
        components: {
            I18n,

        },

        methods:{
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;

                window.scrollTo(0, top);
            },
            goTo(){
                window.open('http://localhost:8080/Fees.pdf', '_blank');
            }
        }
    }
</script>
<style lang="scss" scoped>

    @import "../../assets/cactus/preloader.css";
    @import "../../assets/cactus/animate.css";
    @import "../../assets/cactus/materialize.min.css";
    @import "../../assets/cactus/p.css";
</style>
